export enum TenantFeatureEnum {
  AccountExpiry = "account_expiry",
  AssetView = "asset_view",
  Checkist = "checklist",
  CognitiveView = "cognitive_view",
  CustomAnnotations = "custom_annotations",
  DebugMenu = "debug_menu",
  DeleteUser = "delete_user",
  HistoricalDifference = "historical_difference",
  MeshTesting = "mesh_testing",
  ModelTouchup = "model_touchup",
  Panorama = "panorama",
  ScanVisRefresh = "scan_vis_refresh",
  SigninLogs = "signin_logs",
  UiRefresh = "ui_refresh",
  AssetV1 = "assetv1",
  AssetV2 = "assetv2",
  Alignment = "webportal_alignment",
  AutoArchive = "auto_archive",
  PdfExport = "pdf_export",
  PerformancePanel = "performance_panel",
  RenderSettings = "render_settings",
  Cursor3D = "cursor_3d",
}

export interface ITenantFeature {
  ["account_expiry"]: boolean;
  ["asset_view"]: boolean;
  ["checklist"]: boolean;
  ["cognitive_view"]: boolean;
  ["custom_annotations"]: boolean;
  ["debug_menu"]: boolean;
  ["delete_user"]: boolean;
  ["historical_difference"]: boolean;
  ["mesh_testing"]: boolean;
  ["model_touchup"]: boolean;
  ["panorama"]: boolean;
  ["scan_vis_refresh"]: boolean;
  ["signin_logs"]: boolean;
  ["ui_refresh"]: boolean;
  ["assetv1"]: boolean;
  ["assetv2"]: boolean;
  ["webportal_alignment"]: boolean;
  ["auto_archive"]: boolean;
  ["pdf_export"]: boolean;
  ["performance_panel"]: boolean;
  ["render_settings"]: boolean;
  ["cursor_3d"]: boolean;
}
