import { HomeWorkOutlined } from "@mui/icons-material";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import HistoryIcon from "@mui/icons-material/History";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import LabelIcon from "@mui/icons-material/Label";
import PaletteIcon from "@mui/icons-material/Palette";
import RuleIcon from "@mui/icons-material/Rule";
import SecurityIcon from "@mui/icons-material/Security";
import SettingsIcon from "@mui/icons-material/Settings";
import TimelineIcon from "@mui/icons-material/Timeline";
import ArchiveSharp from "icons/ArchiveSharp";
import React from "react";
import { lazy } from "react";
import { TenantFeatureEnum } from "redux/api/types";

import { PERMISSION_NAME } from "hooks/usePermission";

const AssetBaseProxy = lazy(() => import("views/Audit/AssetBaseProxy"));
const CaptureHistoryPage = lazy(() => import("views/inspection/CaptureHistoryPage/CaptureHistoryPage"));
const SettingsPage = lazy(() => import("views/SettingsPage/SettingsBasePage"));
const AppearancePage = lazy(() => import("views/AppearancePage/AppearancePage"));
const KeyTermsPage = lazy(() => import("views/KeyTermsPage/KeyTermsPage"));
const NotImplementedPage = lazy(() => import("views/NotImplementedPage"));

const FacilityPage = lazy(() => import("views/inspection/AlignmentPage/AlignmentPage"));
const ServerStatusPage = lazy(() => import("views/ServerStatusPage/ServerStatusPage"));
const ExporterPage = lazy(() => import("views/ExporterPage/ExporterPage"));
const ToolKitPage = lazy(() => import("views/ToolKitPage/ToolKitPage"));

export interface PortalRoute {
  path: string;
  name: string;
  id: string;
  icon: any;
  component: React.LazyExoticComponent<() => JSX.Element>;
  highLevel: boolean;
  subroutes: string[];
  adminOption?: boolean;
  hideSiteFilter?: boolean;
  description?: string;
  featureFlagName?: TenantFeatureEnum;
  removeTitleBar?: boolean;
  requiredPermissions?: PERMISSION_NAME[];
  dalmatianDesign?: boolean;
}

export interface PortalRouteDict {
  [key: string]: PortalRoute;
}

const portalRoutes: PortalRouteDict = {
  assetPage: {
    path: "/assets",
    name: "Assets",
    id: "asset-page",
    icon: <HomeWorkOutlined />,
    component: AssetBaseProxy,
    highLevel: true,
    subroutes: [],
    removeTitleBar: true,
    dalmatianDesign: true,
  },
  archivePage: {
    path: "/archive",
    name: "Archive",
    id: "archive-page",
    icon: <ArchiveSharp />,
    component: AssetBaseProxy,
    highLevel: true,
    removeTitleBar: true,
    subroutes: [],
    requiredPermissions: [PERMISSION_NAME.ASSET],
  },
  toolKitPage: {
    path: "/toolkit",
    name: "ToolKit",
    id: "toolkit-page",
    icon: <RuleIcon />,
    component: ToolKitPage,
    highLevel: true,
    subroutes: [],
    removeTitleBar: true,
    requiredPermissions: [PERMISSION_NAME.CHECKLISTS],
  },
  facilityPage: {
    path: "/facility",
    name: "View Facility",
    id: "facility-page",
    icon: <BusinessRoundedIcon />,
    component: FacilityPage,
    highLevel: false,
    subroutes: [],
  },
  ServerStatusPage: {
    path: "/status",
    name: "Status",
    id: "status-page",
    icon: <TimelineIcon />,
    component: ServerStatusPage,
    highLevel: true,
    subroutes: [],
  },
  systemSettingsPage: {
    path: "/systemsettings",
    name: "Settings",
    id: "settings-page",
    icon: <SettingsIcon />,
    component: SettingsPage,
    highLevel: false,
    subroutes: [],
    removeTitleBar: true,
    requiredPermissions: [
      PERMISSION_NAME.ADMIN,
      PERMISSION_NAME.USER,
      PERMISSION_NAME.ROLES,
      PERMISSION_NAME.SITE,
      PERMISSION_NAME.TEAM,
    ],
    adminOption: true,
    dalmatianDesign: true,
  },
  appearancePage: {
    path: "/systemsettings/appearance",
    name: "Appearance",
    id: "appearance-page",
    icon: <PaletteIcon />,
    component: AppearancePage,
    highLevel: false,
    subroutes: [],
    requiredPermissions: [PERMISSION_NAME.SUPERUSER],
    description: "Customize the look and feel of the Portal and Client Applications.",
  },
  keyTermsPage: {
    path: "/systemsettings/keyterms",
    name: "Key Terms",
    id: "key-terms-page",
    icon: <LabelIcon />,
    component: KeyTermsPage,
    highLevel: false,
    subroutes: [],
    requiredPermissions: [PERMISSION_NAME.ADMIN],
    description: "Manage the phrases shown to users in the Client Application and the Expert Portal.",
  },
  securityPage: {
    path: "/systemsettings/security",
    name: "Security",
    id: "security-page",
    icon: <SecurityIcon />,
    component: NotImplementedPage,
    highLevel: false,
    subroutes: [],
    requiredPermissions: [PERMISSION_NAME.ADMIN],
    description: "Manage security tools including IP restrictions.",
  },
  captureHistoryPage: {
    path: "/audit/capturehistory",
    name: "Pose Capture History",
    id: "capture-history-page",
    icon: <HistoryIcon />,
    component: CaptureHistoryPage,
    highLevel: false,
    subroutes: [],
  },
  exporterPage: {
    path: "/export",
    name: "Export",
    id: "export-page",
    icon: <ImportExportIcon />,
    component: ExporterPage,
    highLevel: true,
    subroutes: [],
    requiredPermissions: [PERMISSION_NAME.SUPERUSER],
  },
};

export default portalRoutes;
